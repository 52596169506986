import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { signOut } from 'firebase/auth';
import { auth } from '../shared/auth';
import Button from 'react-bootstrap/Button';
import apiHost from '../shared/apiHost';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminDashboard = () => {
    const [data, setData] = useState({ total: 0, users: [] });
    const navigate = useNavigate();

    const handleRowClick = (userId) => {
        navigate(`/user-profile/${userId}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/admin-login');
                return;
            }

            try {
                const result = await axios.get(`${apiHost}/admin/listUsers`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setData(result.data);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    alert('You are not authorized');
                }
                navigate('/admin-login');
            }
        };

        fetchData();
    }, [navigate]);

    const handleSignOut = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.removeItem('token');
            console.log('Sign-out successful.');
            navigate('/admin-login')
        }).catch((error) => {
            // An error happened.
            console.error('An error happened during sign-out:', error);
        });
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { timeZone: 'America/New_York', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
    };

    return (
        <div>
            <h1>Welcome to the admin dashboard</h1>
            <Button onClick={handleSignOut}>Sign Out</Button>
            <div>
                <h1>Total Users: {data.total}</h1>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Email Verified</th>
                            <th>Disabled</th>
                            <th>Last Signed In</th>
                            <th>Creation Time</th>
                            <th>Last Refresh Time</th>
                            <th>Token Valid After</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.users.map(user => (
                            <tr key={user.uid} onClick={() => handleRowClick(user.uid)}>
                                <td>{user.email}</td>
                                <td>
                                    <Form.Check type="checkbox" checked={user.emailVerified} readOnly />
                                </td>
                                <td>
                                    <Form.Check type="checkbox" checked={user.disabled} readOnly />
                                </td>
                                <td>{user.lastSignInTime ? formatDate(user.lastSignInTime) : 'N/A'}</td>
                                <td>{formatDate(user.creationTime)}</td>
                                <td>{user.lastRefreshTime ? formatDate(user.lastRefreshTime) : 'N/A'}</td>
                                <td>{formatDate(user.tokenValidAfter)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default AdminDashboard;
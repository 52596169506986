
import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../shared/auth';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/admin-dashboard');
        }

    }, [navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        setMessage(null);

        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                const token = await user.getIdToken();

                if (user) {
                    auth.currentUser.getIdTokenResult().then((idTokenResult) => {
                        if (idTokenResult.claims.admin) {
                            localStorage.setItem('token', token);
                            const expiresIn = 3600; // 1 hour
                            localStorage.setItem('token', token);
                            setTimeout(() => {
                                localStorage.removeItem('token');
                            }, expiresIn * 1000);
                            navigate('/admin-dashboard');
                            console.log('User is an admin');
                        } else {
                            // User is not an admin.
                            setMessage("no admin found with this email and password. Please try again.");
                            console.log('User is not an admin');
                        }
                    });
                } else {
                    // Fetch failed
                    setMessage("no user found with this email and password. Please try again.");
                }
            })
            .catch((error) => {
                const errorMessage = error.message;
                setMessage(errorMessage);
            });

        setEmail('');
        setPassword('');

        setIsLoading(false);
    };

    return (
        <div className="d-flex justify-content-center mt-5 p-3" style={{ backgroundColor: "#f8f9fa" }}>
            <div className="col-lg-4 col-md-6 col-sm-8">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} required />
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} required minLength="6" />
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={isLoading} className='mt-4'>
                        {isLoading ? 'Loading...' : 'Login'}
                    </Button>

                    {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    {message && <Alert variant="success" className="mt-3">{message}</Alert>}
                </Form>
            </div>
        </div>
    );
};

export default AdminLogin;
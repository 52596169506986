import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
    // const [user, loading] = useAuthState(auth);
    const location = useLocation();
    const token = localStorage.getItem('token');

    return (
        token ? children : <Navigate to="/" state={{ from: location }} replace />
    );
}

export default PrivateRoute;
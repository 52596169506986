// UserProfile.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import apiHost from '../shared/apiHost';

const UserProfile = () => {
    const { userId } = useParams();
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                alert('You are not authorized');
                return;
            }

            try {
                const result = await axios.get(`${apiHost}/admin/getUserProfile`, {
                    params: {
                        userId: userId
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProfile(result.data.profile);
            } catch (error) {
                console.error('Error fetching user profile:', error);
                if (error.response && error.response.status === 403) {
                    alert('You are not authorized');
                }
            }
        };

        fetchData();
    }, [userId]);

    if (!profile) {
        return null;
    }


    if (Object.keys(profile).length === 0) {
        return <p style={{ marginTop: '2rem' }}>No data yet</p>;
    }

    return (
        <Card style={{ width: '18rem', marginTop: '2rem', textAlign: 'left' }}>
            <Card.Body>
                <Card.Title>{profile.firstName} {profile.lastName}</Card.Title>
                {profile.birthday && <Card.Subtitle className="mb-2 text-muted">{profile.birthday}</Card.Subtitle>}
                {profile.gender && <Card.Text>Gender: {profile.gender}</Card.Text>}
                {profile.bio && <Card.Text>Bio: {profile.bio}</Card.Text>}
                {profile.interests && <Card.Text>Interests: {profile.interests.join(', ')}</Card.Text>}
            </Card.Body>
        </Card>
    );
};

export default UserProfile;
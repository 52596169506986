import React, { useEffect, useState } from 'react';
import { FaHeart, FaHardHat } from 'react-icons/fa';
import moment from 'moment';
import androidIcon from '../images/androidDownload.png';
import iosIcon from '../images/appleDownload.svg';
import './ProjectPage.css';

function ProjectPage() {
    const [timeSpent, setTimeSpent] = useState(moment().diff(moment('2024-01-25'), 'seconds'));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeSpent(timeSpent => timeSpent + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const seconds = timeSpent % 60;
    const minutes = Math.floor(timeSpent / 60) % 60;
    const hours = Math.floor(timeSpent / 3600) % 24;
    const days = Math.floor(timeSpent / 86400);

    return (
        <div className="project-page">
            <h1>Welcome to Project Milan <FaHeart /></h1>
            <p>We're building something beautiful for you. Please be patient, it's worth the wait! <FaHardHat /></p>
            <p className="clock-intro">We've been tirelessly working on our app for:</p>
            <div className="clock">
                <span>Days: {days}</span> | <span>Hours: {hours}</span> | <span>Minutes: {minutes}</span> | <span>Seconds: {seconds}</span>
            </div>
            <p className="clock-outro">Every second spent is a step closer to bringing you our amazing app.</p>
            <div className="app-announcement">
                <div className="app-column">
                    <img className="app-icon" src={androidIcon} alt="Android icon" />
                    <h2>Android App Coming Soon</h2>
                    <p>We're working hard to bring our app to Android. Stay tuned for updates!</p>
                </div>
                <div className="app-column">
                    <img className="app-icon" src={iosIcon} alt="iOS icon" />
                    <h2>iOS App Coming Soon</h2>
                    <p>We're working hard to bring our app to iOS. Stay tuned for updates!</p>
                </div>
            </div>
        </div>
    );
}

export default ProjectPage;
import './App.css';
import ProjectPage from './components/ProjectPage'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from './components/PrivateRoute';
import UserProfile from './pages/UserProfile';

function App() {
  return (
    <div className="container">
      <Router>
        <Routes>
          <Route path="/" element={<ProjectPage />} />
          <Route path="/admin-login" element={<Login />} />
          <Route path="/admin-dashboard" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
          <Route path="/user-profile/:userId" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
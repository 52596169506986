let apiHost;
switch (window.location.hostname) {
    case 'projectmilan.net':
        apiHost = 'https://api.projectmilan.net';
        break;
    case 'dev.projectmilan.net':
        apiHost = 'https://dev.api.projectmilan.net';
        break;
    default:
        apiHost = 'https://dev.api.projectmilan.net';
        break;
}

export default apiHost;
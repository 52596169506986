import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB894Fz8dI62pdPxTJHosacQFRdefHf8YM",
    authDomain: "project-milan-dev.firebaseapp.com",
    projectId: "project-milan-dev",
    storageBucket: "project-milan-dev.appspot.com",
    messagingSenderId: "1079718637669",
    appId: "1:1079718637669:web:32236150731232e564968c",
    measurementId: "G-K76PQHVD6F"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };